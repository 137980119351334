import React, { useEffect, useState, useContext } from "react";
import {
  AppTopNaviDivider,
  AppTopNavi,
  Button,
  WithPopup,
  Popup,
  Icon
} from "@abb/abb-common-ux-react";
import avtar from "../../assets/Avatar.png";
import logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { RootContext } from "../../contexts/root-context";
import { Breadcrumb } from 'react-bootstrap';
import { useLocation, Link } from 'react-router-dom';
import UserSession from "../Authentication/user-session";
import {BASE_URL_LOG} from "../../strings";

import "./navbar.css";
function Navbar() {
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentPage, setCurrentPage] = useState(location.pathname);
  const [monochrome] = React.useState(false);
  const [loggedInUserInfo, setLoggedInUserInfo] = React.useState();
  const [setIsOpen] = React.useState(false);
  const [setIsControlledOpen] = React.useState(false);
  const { setisnotify } = useContext(RootContext);
  useEffect(() => {
    setCurrentPage(location.pathname)
  }, [location.pathname])

  useEffect(() => {
    // Split the pathname into an array of path segments
    const pathSegments = location.pathname.split('/').filter((segment) => segment !== '');
    // Generate breadcrumbs based on the path segments
    const newBreadcrumbs = pathSegments.map((segment, index) => ({
      label: segment,
      path: `${pathSegments.slice(0, index + 1).join('/')}`,
    }));
    setBreadcrumbs(newBreadcrumbs);
    extractUserDetailsFromLocalStorage()
  }, [location.pathname]);

  const extractUserDetailsFromLocalStorage = () => {
    const userDetails = JSON.parse(localStorage.getItem("USER_INFO"));
    setLoggedInUserInfo(userDetails);
  }

  let navigate = useNavigate();
  const openSettingsPage = () => {
    navigate(`../settings`);
  }
  const logout = async () => {
    try {
      await Auth.signOut();
      const userDetails = JSON.parse(localStorage.getItem("USER_INFO"));
      let USER_ID = userDetails.email;
      fetch(BASE_URL_LOG + `logout?userId=${USER_ID}`)
      localStorage.clear();
      setisnotify("");
    } catch (error) {
    } finally {
      navigate('/');
      reloadPage();
    }
  }
  const defaultBreadcrumb = {
    label: 'Home',
    path: 'substation-explorer',
  };

  const reloadPage = () => {
    window.location.reload();
  }
  return (
    <>
      <div className="row">
        <UserSession />
        <div className="header-container">
          <div className="brand">
            <div>
              <img className="avtar-icon" src={logo} alt="" />
            </div>
            <div className="brand-name">
              <div className="project-name">
                <AppTopNaviDivider />
                <div>
                  <div className="title-name">
                    <span>CogniEN<sup id="super">TM</sup></span>
                    <span>{currentPage.includes("substation-explorer") ? "DR Monitoring System" : ""}</span>
                  </div>
                  {currentPage.includes("substation-explorer") ?
                    "" : <Breadcrumb style={{ "width": "600px", "color": "black" }}>
                      <Breadcrumb.Item linkAs={Link} linkProps={{ to: defaultBreadcrumb.path }}>
                        <Icon sizeClass="small" name="abb/home" />

                      </Breadcrumb.Item>
                      &nbsp;

                      {breadcrumbs.map((breadcrumb, index) => (
                        <>
                          &nbsp;{">"} &nbsp;
                          {breadcrumb?.label?.toString().replace("%20", " ")}
                        </>
                      ))}
                    </Breadcrumb>}
                </div>
              </div>
            </div>
          </div>

          <AppTopNavi monochrome={monochrome} allowScroll={true}>
          </AppTopNavi>

          {loggedInUserInfo?.role == "OperationalManager" ? <Button
            icon="abb/settings"
            text="Settings"
            onClick={openSettingsPage}
            shape="pill"
            type="normal"
          /> : ""}
          <Button
            text="Logout"
            onClick={logout}
            shape="pill"
            type="primary-black"
          />
          <div className="profile">
            <WithPopup>
              <span style={{ cursor: "pointer" }}>
                {" "}
                <img className="avtar-icon" src={avtar} alt=""
                />
              </span>
              <Popup
                trigger='hover'
                position='bottom right'
                closeOnEscape='true'
                onOpen={() => {
                  setIsOpen(true);
                  setIsControlledOpen(true);
                }}>
                <div>
                  {(
                    <div ><p>{loggedInUserInfo?.email}</p></div>
                  )}
                </div>
              </Popup>
            </WithPopup>
          </div>
        </div>
      </div>
    </>
  );
}
export default Navbar;
