import React, { useEffect, useContext, useState } from "react";
import {
  Link,
  Datagrid,
  Dialog,
  Spinner,
  Icon,
  Button,
} from "@abb/abb-common-ux-react";
import axios from "axios";
import { RootContext } from "../../contexts/root-context";
import {
  ERROR_LIST,
  BASE_URL,
} from "../../strings";
import "./disturbance-measurements.css";

import NotificationExample from "../Notification/notification";
import { convertUtcToLocalString, decryptData } from "../../util";
import { getToken } from "../Authentication/get-token";
function DisturbanceMeasurementData() {
  const {
    startTS,
    endTS,
    setdrmetrics,
    isnotify,
    drwidgetConfig,
    setdrwidgetConfig,
    setisnotify,
    totalDistRowCounts,
    settotalDistRowCounts,
  } = useContext(RootContext);

  const [isSpin, setispin] = useState(true);
  const [StreamUrl, setStreamUrl] = React.useState();
  const [error, setError] = useState(false);
  const [drAckStatus, setDrAckStatus] = useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState();
  const [data, setData] = React.useState([]);
  const [confirmDRAck, setConfirmDRAck] = useState(false);
  const [currentDrIndex, setcurrentDrIndex] = useState("");
  const [sortOrder, setSortOrder] = React.useState({
    "field": "faultTime",
    "desc": true
  });
  const [fileName, setFileName] = useState("");

  const initialColumns = React.useMemo(() => [
    {
      fieldKey: "s3Location", title: "FILE NAME", sortFn: (a, b, field) => {

        const valA = a.s3Location.props.rel;
        const valB = b.s3Location.props.rel;

        return valA < valB ? -1 : valA > valB ? 1 : 0;
      },
    },
    { fieldKey: "deviceId", title: "RELAY ID" },
    {
      fieldKey: "faultTime", title: "FAULT TIME"
    },
    {
      fieldKey: "action", title: "ACTION"
    },
  ]);
  const [columns] = React.useState(initialColumns);

  useEffect(() => {
    getApiData();
    fetchDRMetrics();
  }, [startTS]);

  useEffect(() => { }, [totalDistRowCounts]);

  const fetchDRMetrics = async () => {
    try {
      const requestBody = {
        // substationList: ["BAY-DS-8451", "gw_id"],
        substationList: JSON.parse(localStorage.getItem('subIds'))
      };
      const response = await axios.post(
        BASE_URL +
        `substation-dr-metrics?startDate=${startTS}&endDate=${endTS}`,
        requestBody, getToken()
      );

      let fetchedMetrics = response.data;
      setdrmetrics(fetchedMetrics);
      setError(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setError(true);
        } else if (status >= 500 && status < 600) {
          setError(true);
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
    }
  };
  const getApiData = async () => {
    setispin(true);
    try {
      const response = await axios.get(
        BASE_URL +
        `dr-list?startDateTime=${startTS}&endDateTime=${endTS}`, getToken()
      );
      let distrRecordsResp = response.data.body;

      settotalDistRowCounts(distrRecordsResp.length);
      let formatedData = distrRecordsResp?.map((v, i) => ({
        fields: {
          s3Location: (
            <Link
              rel={v.s3Location}
              href=""
              onClick={() => handleDRfileLink(v.s3Location)}
              target="_blank"
              title="data"
            >
              <Icon
                className="open-window"
                name={"abb/" + "open-in-new-window"}
                sizeClass="small"
              />
              <span className="dr-filename">{v?.s3Location ? v?.s3Location.split(".")[0] : ""} </span>
            </Link>
          ),
          deviceId: v.deviceId,
          //
          faultTime: convertUtcToLocalString(v?.faultTime.replace('T', ' ').substring(0, 16)),
          action: v.ack ? (
            <>
              <Icon name="abb/check-mark-circle-1" className="charged" />
              <span title="acknowledged">Acknowledged</span>
            </>
          ) : (
            <Button
              disabled={JSON.parse(localStorage.getItem("USER_INFO"))?.permissions?.disturbanceRecordAcknowledgment === "false"}
              id="ack-button"
              icon="abb/check-mark-circle-2"
              sizeClass={"small"}
              type="ghost"
              title="acknowledge"
              text="Acknowledge"
              onClick={() => acknowledgeDRFile(v.s3Location, v.storageId)}
              shape={"pill"}
            />
          ),
        },
      }));

      setData(formatedData);
      setError(false);
      setispin(false);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };
  function handleDRfileLink(fileName) {
    setisnotify("true");
    getAppStremUrl(fileName);
  }

  function getAppStremUrl(fileName) {

    let appsStreamURL =
      BASE_URL + `dr-generate?fileName=${fileName}&userId=${decryptData(localStorage.getItem('logged_in_user'))?.replace(/"/g, '')}`;
    //   BASE_URL + `dr-generate?fileName=${fileName}&userId=123`;
    axios
      .get(appsStreamURL, getToken())
      .then((response) => {
        setisnotify("");
        window.open(response.data.url, "_blank");
        setispin(false);
      })
      .catch(function (error) {
        setisnotify("error");


      })
      .finally(() => { });
  }

  const acknowledgeDRFile = (filename, storageId) => {
    setdrwidgetConfig([{ ...drwidgetConfig[0], currentFile: filename }]);
    setFileName(filename);
    setConfirmDRAck(true);
    setcurrentDrIndex(storageId);
  };


  const approveAck = async () => {
    setConfirmDRAck(true);
    try {
      await axios.get(
      // const response = await axios.get(
        BASE_URL + `dr-doAck?storageId=${currentDrIndex}`, getToken()
      );

      setConfirmDRAck(false);
      setisnotify("dr-acked");
      fetchDRMetrics();
      getApiData();
      setTimeout(() => {
        setisnotify("");
        setcurrentDrIndex("");

      }, 3000);
    } catch (error) {
      if (error.response) {
        const status = error.response.status;
        if (status >= 400 && status < 500) {
          setispin(false);
          setError(true);
        } else if (status >= 500 && status < 600) {
          setispin(false);
          setError(true);
        } else {
          setispin(false);
          setError(true);
        }
      } else {
        setError(true);
        setispin(false);
      }
    }
  };
  return (
    <>
      {isnotify == "dr-acked" ? (
        <NotificationExample notitype={isnotify} />
      ) : (
        ""
      )}

      {confirmDRAck && (
        <div className="overlay-dr">
          <div className="dialog-container">


            <Dialog
              className="dr-confim-ack"

              hideBackground={true}
              dimBackground={true}
              showCloseButton={false}
              closeOnEscape={false}
              closeOnLostFocus={false}

              onClose={() => setConfirmDRAck(false)}


              title="Confirm Acknowledge"
              standardButtonsOnBottom={[
                {
                  text: "No",
                  type: "discreet-blue",
                  handler: (dlg) => setConfirmDRAck(false),
                },
                {
                  text: "Yes",
                  type: "primary-blue",
                  handler: (dlg) => approveAck(),
                },
              ]}
            >
              <div>Are you sure you want to acknowledge : {fileName}</div>
            </Dialog>
          </div>
        </div>
      )}
      {isSpin ? (
        <Spinner
          style={{ margin: "auto" }}
          color="dark-grey"
          sizeClass="small"
        />
      ) : (
        <>
          {error ? (
            <span
              style={{ margin: "auto" }}
              color="dark-grey"
              sizeClass="small"
            >
              {ERROR_LIST.ERROR_FETCH_DATA}
              <br />
              <Link
                style={{ color: "#1f1f1f" }}
                onClick={() => {
                  getApiData();
                }}
              >
                Retry
              </Link>
            </span>
          ) : (
            <>
              {data.length > 0 ? (
                <Datagrid
                  className="table-style"
                  data={data}
                  columns={data.length > 0 ? columns : ""}
                  pageSize={pageSize}
                  page={currentPage}
                  rowHeight="small"
                  enableSorting={true}
                  sortOrder={sortOrder}
                  onSort={(so) => { if (so) { } }}
                />
              ) : (
                <p className="center-text-error">{ERROR_LIST.NO_RECORDS}</p>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default DisturbanceMeasurementData;
